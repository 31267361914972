.search-panel {
  z-index: 1;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 300px;

  border: 1px solid #bbb;
  border-radius: 3px;
  background-color: white;
  box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.15);

  padding: 20px 15px 0 15px;

  max-height: 90%;
  overflow-y: auto;

  h3 {
    margin-top: 0;
  }

  .year-controls {
    text-align: center;
    margin-top: 15px;
  }

  .main-layers {
    margin-top: 15px;
  }

  .divider {
    margin: 10px 0;
  }
  .controls {
    text-align: center;
  }
  .last {
    margin-bottom: 15px;
  }
  .note {
    font-size: 10px;
    font-style: italic;
  }
}