.legend {
  h4 {
    margin: 10px 0 10px;
  }
  h5 {
    margin: 8px 0 4px;
    padding: 0;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    .legendIcon {
      display: inline-block;
      width: 12px;
      height: 12px;
      border: 2px solid #777;
      border-radius: 2px;
      margin: 0px 5px 0 0;
      background-size: contain;
    }
    .legendText {
      font-size: 14px;
      position: relative;
      top: -3px;
    }
  }
}