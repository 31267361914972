#atlas-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .popup {
    h3, p {
      margin: 0;
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
    }
    h4 {
      margin: 5px 0 0;
      font-size: 14px;
      line-height: 18px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
    }
    .districts {
      font-style: italic;
    }
  }
}